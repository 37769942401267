export default () => ({
  Status: '',
  OrderId: null,
  OrderNumber: null,
  CustomerFirstName: '',
  CustomerLastName: '',
  Price: 0,
  Currency: '',
  CreatedAt: '',
  UpdatedAt: '',
  ItemsCount: 0,
  AddressBilling: null
});
